import { Component } from "@angular/core"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { LoggedLayoutService } from "@puntaje/shared/cui"

@Component({
    templateUrl: "home_ensayos.component.html"
})
export class HomeEnsayosComponent extends MenuComponent {
    menuItems: MenuItemBoxData[] = []

    constructor(protected store: Store<State>, protected loggedLayoutService: LoggedLayoutService) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })

        this.setMenuItems()
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo/" + asignaturasByEvaluacionTipo["paes"][0].id,
                params: { tipo_instrumento: "paes" },
                label: "Generar Evaluacion",
                text: "Genera evaluaciones y compártelas con tus estudiantes.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/evaluaciones",
                params: { tipo_instrumento: "paes", asignatura_id: asignaturasByEvaluacionTipo["paes"][0].id },
                label: "Historial de evaluaciones compartidas, no compartidas y presenciales",
                text: "Encuentra aquí la lista de todas las evaluaciones compartidas, no compartidas y presenciales.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/ensayos/progreso/",
                params: { tipo_instrumento: "paes" },
                label: "Progreso de tus estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            },
            {
                route: "/ensayos/estadisticas/",
                params: { tipo_instrumento: "paes", asignatura_id: asignaturasByEvaluacionTipo["paes"][0].id },
                label: "Estadísticas Evaluación",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en evaluaciones.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/evaluacion_instancias/subir",
                label: "Cargar Resultados",
                text: "Sube resultados de evaluaciones a partir de una hoja de respuesta o una planilla CSV.",
                linkText: "Cargar",
                icon: "hacer-prueba"
            }
        ]
    }
}
