<nav class="navbar navbar-default">
    <div class="navbar-collapse collapse nav-justified-menu">
        <ul class="nav navbar-nav">
            <li routerLinkActive="active-menu">
                <!--  <a [routerLink]="['/dudas_categorias']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a> -->
                <!--<a [routerLink]="['/dudas']" role="button"><ogr-icon name='dialogos' align="top"></ogr-icon>&nbsp;Comunidad</a>-->
            </li>
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/ensayos']" role="button">
                    <ogr-icon name="prueba" align="top"></ogr-icon>
                    &nbsp;Evaluaciones
                </a>
            </li>
            <!--            <li routerLinkActive="active-menu">
                <a [routerLink]="['/pruebas']" role="button"><ogr-icon name='guia' align="top"></ogr-icon>&nbsp;Pruebas curriculares</a>
            </li> -->
            <li routerLinkActive="active-menu">
                <a [routerLink]="['/bibliotecas']" role="button">
                    <ogr-icon name="libro2-o" align="top"></ogr-icon>
                    &nbsp;Biblioteca
                </a>
                <a [routerLink]="['/biblioteca']" style="display: none; pointer-events: none; visibility: hidden"></a>
                <a [routerLink]="['/materiales']" style="display: none; pointer-events: none; visibility: hidden"></a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length == 0" routerLinkActive="active-menu">
                <a [routerLink]="['/grupo_usuarios']" role="button">
                    <ogr-icon name="comunidad-o" align="top"></ogr-icon>
                    &nbsp;Mis cursos
                </a>
            </li>
            <li *ngIf="authService.getEstablecimientos().length != 0" routerLinkActive="active-menu">
                <a [routerLink]="['/establecimientos']" role="button">
                    <ogr-icon name="establecimiento-o" align="top"></ogr-icon>
                    &nbsp;Administrar
                </a>
            </li>
        </ul>
    </div>
</nav>
