<cui-evaluation-layout [contenidoLateralXL]="false">
    <cui-layout-header>
        <navbar></navbar>
        <topmenu></topmenu>
    </cui-layout-header>
    <cui-layout-footer>
        <footer-component></footer-component>
        <simple-modal></simple-modal>
    </cui-layout-footer>
</cui-evaluation-layout>
