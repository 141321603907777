import { Component, OnInit, ViewChild } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Asignaturas } from "@puntaje/nebulosa/api-services"
import { AuthService, ZopimService } from "@puntaje/shared/core"
import { State } from "@puntaje/puntaje/store"
import { Store } from "@ngrx/store"
import { LoggedLayoutService } from "@puntaje/shared/cui"

@Component({
    templateUrl: "home.component.html",
    styleUrls: ["home.component.scss"]
})
export class HomeComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected asignaturaService: Asignaturas,
        public authService: AuthService,
        protected store: Store<State>,
        protected zopimService: ZopimService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        super.ngOnInit()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })

        this.setMenuItems()
        this.zopimService.showZopim()
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/ensayos/generar_ensayo/" + asignaturasByEvaluacionTipo["paes"][0].id,
                params: { tipo_instrumento: "ensayo" },
                label: "Generar Ensayo PSU",
                text: "Genera ensayos similares a la PSU y compártelos con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/pruebas/generar_prueba/" + asignaturasByEvaluacionTipo["curricular"][0].id,
                params: { tipo_instrumento: "curricular" },
                label: "Realizar prueba curricular",
                text: "Genera pruebas de contenido curricular y compártelas con tus estudiantes. Con opciones personalizables.",
                linkText: "Comenzar",
                icon: "hacer-prueba"
            },
            {
                route: "/ensayos/estadisticas/",
                params: { tipo_instrumento: "ensayo", asignatura_id: 1 },
                label: "Estadísticas PSU",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en ensayos PSU.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/estadisticas/",
                params: { tipo_instrumento: "curricular", asignatura_id: 1 },
                label: "Estadísticas de pruebas curriculares",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            }
        ]
    }
}
